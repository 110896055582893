.singlePost {
  flex: 9;
}

.singlePostWrapper {
  padding: 20px;
  padding-right: 0;
  position: relative;
}

.singlePostImg {
  width: 100%;
  max-height: 500px;
  border-radius: 5px;
  object-fit: cover;
  display: block;
}
.fa-chevron-circle-left {
  position: absolute;
  top: 240px;
  left: 30px;
  cursor: pointer;
  color: rgb(133, 64, 224);
  font-size: 25px;
}
.fa-chevron-circle-right {
  position: absolute;
  top: 240px;
  right: 15px;
  cursor: pointer;
  font-size: 25px;
  color: tomato;
}
.additionalInfoS {
  display: flex;
  justify-content: space-between;
}
.infoS {
  font-size: 16px;
  letter-spacing: 0.5px;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-size: 28px;
  font-family: "Lora", sans-serif;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: teal;
}
.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #be9656;
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}

.singlePostAuthor {
  margin-left: 5px;
}

.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}
.shareIcon {
  width: 30px;
  margin-left: 5px;
}
.shareButton {
  outline: "none";
}
@media screen and (max-width: 900px) {
  .singlePostWrapper {
    padding: 0;
  }
  .singlePostDesc {
    margin: 0 10px 30px 10px;
  }
  .fa-chevron-circle-left {
    left: 15px;
    top: 150px;
  }
  .fa-chevron-circle-right {
    top: 150px;
  }
  .additionalInfoS {
    display: block;
  }
}
