@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap);
/* .top {
  width: 100%;
  height: 60px;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  font-family: "Josefin Sans", sans-serif;
}

.topRight {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topIcon {
  font-size: 20px;
  margin-right: 10px;
  color: #444;
  cursor: pointer;
}

.topCenter {
  flex: 8;
}

.topList {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.topListItem {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.topListItem:hover {
  color: gray;
}

.topImg {
  width: 80px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
} */

.topnav {
  background-color: #333;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
} /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
}

.headerImg {
  width: 100%;
  height: 450px;
  margin-top: 10px;
  object-fit: cover;
}
.header {
  position: relative;
}
.headerTitleSm {
  position: absolute;
  top: 20%;
  color: rgb(163, 35, 189);
  font-size: 1.5rem;
  font-weight: 700;
}

.headerTitleLg {
  position: absolute;
  top: 40%;
  font-size: 3rem;
  color: rgb(22, 29, 134);
  font-weight: 800;
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 320px) and (max-device-width: 480px) and (min-device-width: 769px) and (max-device-width: 1024px) and (min-device-width: 481px) and (max-device-width: 768px), screen and (min-device-width: 1025px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-device-width: 320px) and (max-device-width: 480px) and (min-device-width: 769px) and (max-device-width: 1024px) and (min-device-width: 481px) and (max-device-width: 768px) {
  .root {
    outline: none;
  }
}

@media screen and (max-device-width: 768px) {
  .sidebar {
    display: none !important;
  }
  .headerImg {
    height: auto;
  }
}
@media screen and (max-device-width: 600px) {
  .headerTitleLg {
    font-size: 2.5rem;
  }
}

.sidebar {
  flex: 3 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 20px;
  padding-bottom: 30px;
  background-color: #fdfbfb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarTitle {
  margin: 10px;
  padding: 5px;
  width: 80%;
  border-top: solid 1px #a7a4a4;
  border-bottom: solid 1px #a7a4a4;
  text-align: center;
  font-family: "Varela Round", sans-serif;
  font-size: 12px;
  line-height: 19px;
  color: #222222;
  font-weight: 600;
}

.sidebarItem > img {
  margin-top: 15px;
  width: 300px;
  height: 250px;
}

.sidebarItem > p {
  padding: 30px;
}

.sidebarList {
  list-style-type: none;
  margin-bottom: 30px;
}

.sidebarListItem {
  display: inline-block;
  width: 50%;
  margin-top: 15px;
  cursor: pointer;
}

.sidebarSocial {
  margin-top: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarIcon {
  font-size: 16px;
  margin-left: 10px;
}

.post {
  width: 385px;
  margin: 0px 25px 40px 25px;
  display: flex;
  flex-direction: column;
}

.postImg {
  width: 385px;
  height: 280px;
  object-fit: cover;
  border-radius: 7px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additionalInfo {
  grid-area: info;
  background-color: whitesmoke;
  border-radius: 7px;
}
.fa-calendar-alt {
  color: #ff5f6d;
  color: -webkit-linear-gradient(to right, #ff5f6d, #ffc371);
  color: linear-gradient(to right, #ff5f6d, #ffc371);
}
.additionalInfo {
  font-size: 12px;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.postCat {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: #be9656;
  line-height: 19px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.postTitle {
  font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 900;
  margin-top: 15px;
  cursor: pointer;
}

.postDate {
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
}

.postDesc {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 900px) {
  .post {
    margin: 10px 0 0 0px;
  }
}

.posts {
  flex: 9 1;
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}

@media screen and (max-width: 900px) {
  .posts {
    margin: 0px;
    justify-content: center;
  }
}

.home {
  display: flex;
}

.settings {
  display: flex;
}

.settingsWrapper {
  flex: 9 1;
  padding: 20px;
}

.settingsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsTitleUpdate {
  font-size: 30px;
  margin-bottom: 20px;
  color: lightcoral;
}

.settingsTitleDelete {
  color: red;
  font-size: 12px;
  cursor: pointer;
}

.settingsForm {
  display: flex;
  flex-direction: column;
}

.settingsPP {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.settingsPP > img {
  height: 70px;
  width: 70px;
  border-radius: 20px;
  object-fit: cover;
}

.settingsPPIcon {
  width: 25px;
  height: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  margin-left: 10px;
  color: white;
  background-color: lightcoral;
  cursor: pointer;
}

.settingsPPButton:focus {
  outline-style: none;
}

.settingsForm > label {
  font-size: 20px;
  margin-top: 20px;
}

.settingsPPInput {
  width: 30%;
}

.settingsForm > input {
  color: gray;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  border: none;
  border-bottom: 1px solid lightgray;
}

.settingsSubmitButton {
  align-self: center;
  width: 150px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: teal;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsSubmitButton:hover {
  background-color: rgb(1, 114, 114);
}

.singlePost {
  flex: 9 1;
}

.singlePostWrapper {
  padding: 20px;
  padding-right: 0;
  position: relative;
}

.singlePostImg {
  width: 100%;
  max-height: 500px;
  border-radius: 5px;
  object-fit: cover;
  display: block;
}
.fa-chevron-circle-left {
  position: absolute;
  top: 240px;
  left: 30px;
  cursor: pointer;
  color: rgb(133, 64, 224);
  font-size: 25px;
}
.fa-chevron-circle-right {
  position: absolute;
  top: 240px;
  right: 15px;
  cursor: pointer;
  font-size: 25px;
  color: tomato;
}
.additionalInfoS {
  display: flex;
  justify-content: space-between;
}
.infoS {
  font-size: 16px;
  letter-spacing: 0.5px;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-size: 28px;
  font-family: "Lora", sans-serif;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: teal;
}
.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #be9656;
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}

.singlePostAuthor {
  margin-left: 5px;
}

.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}
.shareIcon {
  width: 30px;
  margin-left: 5px;
}
.shareButton {
  outline: "none";
}
@media screen and (max-width: 900px) {
  .singlePostWrapper {
    padding: 0;
  }
  .singlePostDesc {
    margin: 0 10px 30px 10px;
  }
  .fa-chevron-circle-left {
    left: 15px;
    top: 150px;
  }
  .fa-chevron-circle-right {
    top: 150px;
  }
  .additionalInfoS {
    display: block;
  }
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.section {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
}
.container {
  width: 80%;
  display: block;
  margin: auto;
  padding-top: 100px;
}
.content-section {
  float: left;
  width: 55%;
}
.image-section {
  float: right;
  width: 45%;
}
.image-section img {
  width: 100%;
  height: auto;
}
.content-section .title {
  text-transform: uppercase;
  font-size: 28px;
}
.content-section .content h2 {
  margin-top: 20px;
  color: #5d5d5d;
  font-size: 21px;
}
.content-section .content p {
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 1.5;
}
.content-section .content .button {
  margin-top: 30px;
}
.content-section .content .button a {
  background-color: #3d3d3d;
  padding: 12px 40px;
  text-decoration: none;
  color: #fff;
  font-size: 25px;
  letter-spacing: 1.5px;
}
.content-section .content .button a:hover {
  background-color: #a52a2a;
  color: #fff;
}
.content-section .social {
  margin: 40px 40px;
}
.content-section .social i {
  color: #a52a2a;
  font-size: 30px;
  padding: 0px 10px;
}
.content-section .social i:hover {
  color: #3d3d3d;
}
.picNumber {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .container {
    width: 80%;
    display: block;
    margin: auto;
    padding-top: 50px;
  }
  .content-section {
    float: none;
    width: 100%;
    display: block;
    margin: auto;
  }
  .image-section {
    float: none;
    width: 100%;
  }
  .image-section img {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0.5;
    margin: auto;
  }
  .content-section .title {
    text-align: center;
    font-size: 19px;
  }
  .content-section .content .button {
    text-align: center;
  }
  .content-section .content .button a {
    padding: 9px 30px;
  }
  .content-section .social {
    text-align: center;
  }
}

.single{
    display: flex;
}
.write {
  padding-top: 50px;
}

.writeImg {
  margin-left: 150px;
  width: 70vw;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
}

.writeForm {
  position: relative;
}

.writeFormGroup {
  margin-left: 150px;
  display: flex;
  align-items: center;
}

.writeIcon {
  width: 25px;
  height: 25px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 50%;
  color: rgb(129, 125, 125);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.writeInput {
  font-size: 30px;
  border: none;
  padding: 20px;
  width: 70vw;
}

.writeInput::-webkit-input-placeholder {
  color: rgb(189, 185, 185);
  font-weight: 400;
}

.writeInput:-ms-input-placeholder {
  color: rgb(189, 185, 185);
  font-weight: 400;
}

.writeInput::placeholder {
  color: rgb(189, 185, 185);
  font-weight: 400;
}

.writeInput:focus {
  outline-style: none;
}

.writeText {
  width: 70vw;
  height: 100vh;
  font-family: inherit;
  font-size: 20px;
}

.writeSubmit {
  position: absolute;
  top: 20px;
  right: 50px;
  color: white;
  background-color: teal;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#projectInputs {
  display: flex !important;

  width: 50%;
}
.selectOptions {
  width: 50%;
}
@media screen and (max-width: 900px) {
  .writeImg {
    margin-left: 30px;
  }
  .writeFormGroup {
    margin-left: 30px;
  }
  .writeText {
    height: 30vh;
  }
}

.form-group {
  width: 50%;
}
.form {
  margin-left: 10%;
  margin-top: 5%;
}

.footer-body {
  /* background: #ff5f6d;
  background: -webkit-linear-gradient(to right, #ff5f6d, #ffc371);
  background: linear-gradient(to right, #ff5f6d, #ffc371); */
  border-top: 1px solid gainsboro;
}

#button-addon1 {
  color: #ffc371;
}

i {
  color: #ffc371;
}

.form-control::-webkit-input-placeholder {
  font-size: 0.95rem;
  color: #aaa;
  font-style: italic;
}

.form-control:-ms-input-placeholder {
  font-size: 0.95rem;
  color: #aaa;
  font-style: italic;
}

.form-control::placeholder {
  font-size: 0.95rem;
  color: #aaa;
  font-style: italic;
}

.form-control.shadow-0:focus {
  box-shadow: none;
}

